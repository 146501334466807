import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { templatesSlice } from "../app/modules/Templates/_redux/slice";
import { categoriesSlice } from "../app/modules/Categories/_redux/slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  templates: templatesSlice.reducer,
  categories: categoriesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
