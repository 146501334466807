import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

const TemplatesPage = lazy(() => import("./modules/Templates/pages/index"));
const Category = lazy(() => import("./modules/Categories/pages/index"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/templates" />

        <ContentRoute path="/templates" component={TemplatesPage} />
        <ContentRoute path="/categories" component={Category} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
