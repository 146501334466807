import React, { useRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken, getInstitutes } from "./authCrud";
import { userRoles } from "../../../../_component/constant";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by accessToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken();

          if (user && user.rouvrDefaultRole !== userRoles.superAdmin) {
            const { data: institutes } = await getInstitutes();
            // const { data: branches } = await getBranches();

            dispatch(props.fulfillInstitutes(institutes));
            // dispatch(props.fulfillBranches(branches));
            // if (branches && branches.length > 0) {
            dispatch(
              props.requestSetInstituteBranch({
                instituteId: institutes[0].id,
                branchId: "",
                displayName: institutes[0].name,
              })
            );
            // }

            // dispatch(props.requestUserPermissions());
          }

          dispatch(props.fulfillUser(user));
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    const authToken = JSON.parse(localStorage.getItem("authToken"));

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillInstitutes([]));
      dispatch(props.fulfillBranches([]));
      dispatch(props.fulfillSetInstituteBranch(undefined));
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
